.video-element {
  height: 100vh;
  width: 100vw;
  min-height: 100%;
  min-width: 100%;
  margin: 0 auto;
}

.qr-close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 45px;
  right: 22px;
  z-index: 10;
}

.qr-close-btn img {
  width: 33.33px;
  height: 33.33px;
}

.qr-close-btn:hover {
  cursor: pointer;
}

.scan-instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.scanner-text {
  font-family: "open-sans-bold";
  font-style: normal;
  font-weight: 900;
  color: rgb(90, 81, 63);
  font-size: 24px;
}

.up-arrow {
  width: 67px;
  height: 67px;
}

.scan-region-highlight-svg {
  stroke: #fff !important;
}

.qr-reader {
  position: relative !important;
}
.scanner-holder .qr-reader section div {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  border: none !important;
  box-shadow: white 0px 0px 0px 5px inset !important;
  width: 400px !important;
  height: 400px !important;
}

.scanner-holder .qr-reader section {
  position: static !important;
  padding: 0 !important;
  padding: 0 !important;
  margin-top: 24px;
}

.scanner-holder .qr-reader section video {
  position: relative !important;
  margin: 0 auto !important;
  width: 90vw !important;
  height: 75vh !important;
}

@media screen and (max-width: 1220px) {
  .scanner-holder .qr-reader section video {
    height: 70vh !important;
  }
}

@media screen and (max-width: 992px) {
  .scanner-holder .qr-reader section video {
    height: 60vh !important;
  }
}

@media screen and (max-width: 800px) {
  .scanner-holder .qr-reader section div {
    width: 200px !important;
    height: 200px !important;
  }
}

@media screen and (max-width: 768px) {
  .scanner-holder .qr-reader section video {
    height: 70vh !important;
  }

  .qr-close-btn img {
    width: 26.67px;
    height: 26.67px;
  }

  .up-arrow {
    width: 54px;
    height: 54px;
  }
}

// @media screen and (max-width: 500px) {
//   .scanner-holder .qr-reader section video {
//     width: 100vw !important;
//     height: 100vh !important;
//   }
// }

@media screen and (min-width: 575px) {
  .scanner-holder .qr-reader section video {
    transform: rotateY(180deg) !important;
  }
}

@media screen and (max-width: 575px) {
  .scanner-holder .qr-reader section {
    margin-top: 0;
  }

  .scan-instructions .scanner-text {
    font-size: 20px;
  }
}

@media screen and (min-width: 500px) {
  .scanner-holder .qr-reader section video {
    transform: rotateY(180deg) !important;
  }
}

@media screen and (max-width: 425px) {
  .scan-instructions .scanner-text {
    font-size: 16px;
    max-width: 340px;
  }
}

.scan-text {
  font-weight: 700;
  font-size: 25px;
  font-family: "open-sans";
  margin-top: 30px;
  text-align: center;
}

.addressError {
  text-align: center;
  margin-top: 150px;
  font-size: 25px;
  font-family: "open-sans";
  color: #5a513f;
  font-weight: 600;
}
