.App {
  min-height: calc(100vh - 100px);
  height: 100%;
}

.header {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 24px;
  /* margin: 24px 60px 0 60px; */
  /* margin: 24px 20px 0 20px;
  width: 100%;
  max-width: 1320px; */
}

.header .logo {
  height: 34px;
  width: 156px;
}
.slick-active {
  z-index: 342423423423;
}
.language-picker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.language-picker .arrow {
  width: 12px;
  height: 6px;
}

.language-picker .lang {
  width: 20px;
  height: 20px;
}

.crest-page {
  background-color: #5a513f;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.crest-title-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 64px;
}

.crest-title-holder h2 {
  font-family: 'open-sans-extra';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.crest-title-holder img {
  position: absolute;
  right: 81px;
  width: 36.67px;
  height: 36.67px;
}

.crestpage-text {
  font-family: 'open-sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  max-width: 708px;
  margin: 0;
}

.crest-img {
  width: 393px;
  height: 368px;
  margin-top: 64px;
}

.crestpage-button {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 0px;
  gap: 10px;
  width: 393px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-top: 48px;

  font-family: 'open-sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #5a513f;
}

@media (max-width: 1440px) {
  .header {
    /* margin: 24px 0 0 0; */
    /* max-width: calc(100vw - 120px); */
  }
}

@media (max-width: 1200px) {
  .header {
    max-width: calc(100vw - 60px);
  }
}

@media (max-width: 1000px) {
  .header {
    max-width: calc(100vw - 40px);
  }
}

@media (max-width: 992px) {
  .crest-title-holder {
    margin-top: 40px;
  }

  .crest-title-holder img {
    right: 32px;
  }
}

@media (max-width: 768px) {
  .crest-title-holder {
    margin-top: 32px;
  }

  .crest-title-holder img {
    width: 29.33px;
    height: 29.33px;
  }

  .crest-img {
    width: 248px;
    height: 232px;
    margin-top: 40px;
  }
}

@media (max-width: 575px) {
  .crest-title-holder h2 {
    font-size: 20px;
  }

  .crestpage-text {
    font-size: 16px;
    margin: 0 20px;
  }

  .crest-img {
    width: 182px;
    height: 170px;
    margin-top: 32px;
  }

  .crestpage-button {
    width: 535px;
    margin-top: 40px;
  }
}

@media (max-width: 425px) {
  .crestpage-button {
    width: 90%;
  }
}
