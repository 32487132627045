.primary-button {
  border: 1px solid #5a513f;
  background: #fff;
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  display: flex;
  padding: 11px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #5a513f;
  text-align: center;
  font-size: 16px;
  font-family: 'open-sans-bold';
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
}

.secondary-button {
  border: 1px solid #5a513f;
  background: #5a513f;
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  display: flex;
  padding: 11px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  text-align: center;
  font-size: 16px;
  font-family: 'open-sans-bold';
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
}

.secondary-button img {
  width: 24px;
  height: 24px;
}

.secondary-button .button-crest-icon {
  width: 21px;
  height: 28px;
  overflow: hidden;
  position: relative;
}

.secondary-button .button-crest-icon img {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 535px) {
  .secondary-button {
    width: 100%;
  }
  .primary-button {
    width: 100%;
  }
}
