@font-face {
  font-family: 'open-sans';
  src: local('open-sans'),
    url(./assets/fonts/muzeum/open-sans.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'open-sans-bold';
  src: local('open-sans-bold'),
    url(./assets/fonts/muzeum/open-sans-bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'open-sans-extra';
  src: local('open-sans-extra'),
    url(./assets/fonts/muzeum/open-sans-extrabold.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'open-sans-semibold';
  src: local('open-sans-semibold'),
    url(./assets/fonts/muzeum/open-sans-semibold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'segoe';
  src: local('segoe'), url(./assets/fonts/cc/Segoe-UI.ttf) format('truetype');
  font-display: swap;
}
